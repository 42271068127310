const CONSTANTS = {
  PROJECT_NAME: 'DY-DEV',
  LAYOUT_PREFIX: '/main',
  API_V1_BASE_URL: process.env.REACT_APP_API_V1_BASE_URL,
  API_V2_BASE_URL: process.env.REACT_APP_API_V2_BASE_URL,
  API_V1_FILE_STORAGE_BASE_URL:
    process.env.REACT_APP_API_V1_FILE_STORAGE_BASE_URL,

  LOGIN_CHECK_TIME: 30 // min
};

export default CONSTANTS;
